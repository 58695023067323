import React from 'react';
import { FaWhatsapp } from "react-icons/fa";
import { CgMail } from "react-icons/cg";
import insta from "../assets/Instagram.gif";
import youtube from "../assets/Youtube.gif";
import linkedIn from "../assets/LinkedIn.gif";
import { motion } from "framer-motion";

const Socialicon = () => {
  return (
    <div className='social_conatainer'>
      


      <div className="mt-[50px]">
      <motion.h3
  className=" sm:text-[30px] font-bold text-center text-white"
  initial={{ opacity: 0, y: -30 }}
  animate={{ opacity: 1, y: 0 }}
  transition={{ duration: 0.5 }}
  style={{
    textShadow: "0px 4px 10px rgba(0,0,0,0.8), 0px 8px 15px rgba(0,0,0,0.6)",
    fontSize: "70px",
    fontFamily: "cursive",
    fontWeight: "bolder",
    textAlign: "center",
    marginTop: "90px"
  }}
>
  Socials
</motion.h3>


  <motion.p
    className="socialicon_p text-lg text-center text-gray-600 mt-4"
    initial={{ opacity: 0, y: -20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
    style={{
      textShadow: "0px 4px 10px rgba(0,0,0,0.8), 0px 8px 15px rgba(0,0,0,0.6)",
      color:"#fff",
      fontSize: "30px",
      fontWeight: "bolder",
    }}
  >
    Join our WhatsApp group to get the latest updates and discuss about the project.
  </motion.p>

  <div className="flex justify-center gap-4 mt-6">
  <motion.button
  className="flex items-center justify-center gap-2 px-6 py-3 bg-[#25D366] text-white rounded-lg shadow-lg transform transition-all duration-300 hover:scale-105 hover:shadow-xl hover:bg-[#128C7E]"
  initial={{ scale: 0.9, rotateY: 10 }}
  animate={{ scale: 1, rotateY: 0 }}
  whileHover={{ scale: 1.1 }}
  whileTap={{ scale: 0.95 }}
  transition={{ duration: 0.3 }}
>
  <FaWhatsapp size={24} /> WhatsApp
</motion.button>


    <motion.button
      className="flex items-center justify-center gap-2 px-6 py-3 bg-[linear-gradient(45deg,_#4285F4,_#BB001B,_#EA4335,_#FBBC05,_#34A853)] p-8 text-white rounded-lg shadow-lg transform transition-all duration-300 hover:scale-105 hover:shadow-xl"
      initial={{ scale: 0.9, rotateY: 10 }}
      animate={{ scale: 1, rotateY: 0 }}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.95 }}
      transition={{ duration: 0.3 }}
    >
      <CgMail size={24} /> Contact Us
    </motion.button>
  </div>
</div>


<motion.p
    className="text-lg text-center text-gray-600 mt-4"
    initial={{ opacity: 0, y: -20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
    style={{
      textShadow: "0px 4px 10px rgba(0,0,0,0.8), 0px 8px 15px rgba(0,0,0,0.6)",
      color:"#fff",
      fontSize: "30px",
      fontWeight: "bolder",
      marginTop: "70px",
    }}
  >
    OR
  </motion.p>
        <div className='Social_cards'>
          <div className='social_card'>
            <img className='insta_img' src={insta} alt='instagram' />
            <a href="https://www.instagram.com/snapedcodecampus/profilecard/?igsh=aWdxY2NjdmN0c2N1" target="_blank" rel="noopener noreferrer">
            <button className='join_button'>Follow Instagram</button>
            </a>
            
          </div>

          <div className='social_card'>
            <img className='insta_img' src={youtube} alt='YouTube' />
            <a href="https://youtube.com/@snapedcodecampus?si=yEwwseMw51I9PXVd" target="_blank" rel="noopener noreferrer">
            <button className='join_button' >Subscribe YouTube</button>
            </a>
            
          </div>
          <div className='social_card'>
            <img className='insta_img' src={linkedIn} alt='linkedIn' />
            <a href="https://www.linkedin.com/company/snaped-code-campus/" target="_blank" rel="noopener noreferrer">
            <button className='join_button' >Join LinkedIn</button>
            </a>
           
          </div>
        </div>


      </div>
  //  </div>
    // </div>
  )
}

export default Socialicon

