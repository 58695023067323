import React, { useState, useEffect } from 'react';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { addDoc, collection, deleteDoc, doc, getDocs } from 'firebase/firestore';
import { auth, db } from '../../../components/firebase';
import Tabs from '../../../components/Tabs';
import Card from '../../../components/notescard';
import Accordions from '../../../components/Dropdowns';

const HVE = () => {
  const [user, setUser] = useState(null);
  const [apNotes, setApNotes] = useState([]);
  const [videos, setVideos] = useState([]); 
  const [videoTitle, setVideoTitle] = useState('');
  const [videoUrl, setVideoUrl] = useState('');
  const [driveLink, setDriveLink] = useState(''); 
  const [driveTitle, setDriveTitle] = useState(''); 
  const adminEmail = 'snaped4338@gmail.com';

  // Fetch notes from Firestore
  useEffect(() => {
    const fetchNotesFromFirestore = async () => {
      const notesSnapshot = await getDocs(collection(db, 'HVENotes'));
      setApNotes(notesSnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    fetchNotesFromFirestore();
  }, []);

  // Fetch videos from Firestore
  useEffect(() => {
    const fetchVideos = async () => {
      const videoCollection = collection(db, 'HVEVideos');
      const videoSnapshot = await getDocs(videoCollection);
      setVideos(videoSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    };
    fetchVideos();
  }, []);

  // Monitor user authentication state
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
    });
    return () => unsubscribe();
  }, []);

  // Google Sign-In
  const handleGoogleLogin = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      setUser(result.user);
    } catch (error) {
      console.error('Error during Google login:', error);
    }
  };

  // File upload handler (using Firestore for Google Drive link)
  const handleDriveLinkUpload = (category) => {
    if (!driveLink || !driveTitle) {
      alert('Please provide both a valid title and Google Drive link.');
      return;
    }

    const newNote = { title: driveTitle, category, link: driveLink };
    addDoc(collection(db, 'HVENotes'), newNote).then(() => {
      setApNotes((prevNotes) => [...prevNotes, newNote]);
      alert('Google Drive link uploaded successfully!');
      setDriveLink(''); // Clear the input after uploading
      setDriveTitle(''); // Clear the title input after uploading
    });
  };

  // Handle deleting a note
  const handleDeleteNote = async (note) => {
    const noteDoc = doc(db, 'HVENotes', note.id);
    await deleteDoc(noteDoc);
    setApNotes(apNotes.filter((n) => n.id !== note.id)); // Remove from state
  };

  
// Handle adding a video
const handleAddVideo = async () => {
  if (!videoTitle || !videoUrl) {
    alert('Please provide both a valid title and video URL.');
    return;
  }

  // Convert YouTube URL to embed format (either for normal video, live stream, or playlist)
  let formattedUrl = '';

  // Check if it's a YouTube live stream
  if (videoUrl.includes('youtube.com/live')) {
    const videoId = videoUrl.split('/').pop();
    formattedUrl = `https://www.youtube.com/embed/${videoId}`;
  }
  // Check if it's a YouTube regular video
  else if (videoUrl.includes('youtube.com/watch?v=')) {
    const videoId = new URL(videoUrl).searchParams.get('v');
    formattedUrl = `https://www.youtube.com/embed/${videoId}`;
  }
  // Check if it's a YouTube playlist
  else if (videoUrl.includes('youtube.com/playlist?list=')) {
    const playlistId = new URL(videoUrl).searchParams.get('list');
    formattedUrl = `https://www.youtube.com/embed/videoseries?list=${playlistId}`;
  } else {
    alert('Please provide a valid YouTube video, live stream, or playlist URL.');
    return;
  }

  const newVideo = { title: videoTitle, link: formattedUrl };

  // Add video to Firestore
  const videoRef = await addDoc(collection(db, 'HVEVideos'), newVideo);

  // Update state with new video
  setVideos((prevVideos) => [...prevVideos, { id: videoRef.id, ...newVideo }]);
  setVideoTitle(''); // Clear title input after adding video
  setVideoUrl(''); // Clear URL input after adding video
};

// Handle deleting a video
const handleDeleteVideo = async (videoId) => {
  const videoDoc = doc(db, 'HVEVideos', videoId);
  await deleteDoc(videoDoc);
  setVideos(videos.filter((video) => video.id !== videoId)); // Remove from state
};

  // Render upload button with Google Drive link input
  const renderUploadButton = (category) => (
    <div className="upload-container">
      {user?.email === adminEmail && (
        <div>
          <input
            type="text"
            placeholder="Note Title"
            value={driveTitle}
            onChange={(e) => setDriveTitle(e.target.value)}
            style={{ marginRight: '10px' }}
          />
          <input
            type="text"
            placeholder="Google Drive Link"
            value={driveLink}
            onChange={(e) => setDriveLink(e.target.value)}
            style={{ marginRight: '10px' }}
          />
          <button onClick={() => handleDriveLinkUpload(category)}>Upload Link</button>
        </div>
      )}
    </div>
  );

  // Render cards with iframe for displaying PDFs from Google Drive links
  const renderCards = (category) => (
    <div className="cards-container">
      {apNotes
        .filter((note) => note.category === category)
        .map((note, index) => (
          <Card
            key={index}
            title={note.title}
            link={note.link}
            onDelete={() => handleDeleteNote(note)}
            showDelete={user?.email === adminEmail}
            user={user}
          >
            {/* Display PDF using iframe for Google Drive links */}
            {note.link && (
              <iframe
                src={`https://drive.google.com/viewerng/viewer?embedded=true&url=${note.link}`}
                width="100%"
                height="500px"
                frameBorder="0"
                title={note.title}
              />
            )}
          </Card>
        ))}
    </div>
  );


// Render video section
const renderVideoSection = () => (
  <div>
    {user?.email === adminEmail && (
      <div style={{ marginBottom: '20px', color: '#000'}}>
        <input
          type="text"
          placeholder="Video Title"
          value={videoTitle}
          onChange={(e) => setVideoTitle(e.target.value)}
          style={{ marginRight: '10px' }}
        />
        <input
          type="text"
          placeholder="YouTube Video, Live Stream, or Playlist URL"
          value={videoUrl}
          onChange={(e) => setVideoUrl(e.target.value)}
          style={{ marginRight: '10px' }}
        />
        <button onClick={handleAddVideo}>Add Video</button>
      </div>
    )}
    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
      {videos.map((video) => (
        <div
          key={video.id}
          style={{
            border: '1px solid #ccc',
            padding: '10px',
            borderRadius: '8px',
            width: '320px',
          }}
        >
          <h5>{video.title}</h5>
          <iframe
            src={video.link}
            title={video.title}
            width="300"
            height="200"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
          {user?.email === adminEmail && (
            <button
              onClick={() => handleDeleteVideo(video.id)}
              style={{ marginTop: '10px' }}
            >
              Delete
            </button>
          )}
        </div>
      ))}
    </div>
  </div>
);


  const accordionContent = [
    <div key="1">
      <p>Human Values: Morals, Values, Ethics, Integrity, Work ethics, Service learning, Virtues, Respect for others, Living peacefully, Caring, Sharing, Honesty, Courage, Valuing time, Cooperation, Commitment, Empathy, Self-confidence, Challenges in the workplace, Spirituality</p>
    </div>,
    <div key="2">
      <p>Engineering Ethics: Senses of engineering ethics, Variety of moral issues, Types of inquiries, Moral dilemma, Moral autonomy, Moral development (theories), Consensus and controversy, Profession, Models of professional roles, Responsibility, Theories about right action (Ethical theories), Self-control, Self-interest, Customs, Religion, Self-respect, Case study: Choice of the theory Engineering as experimentation, Engineers as responsible experimenters, Codes of ethics, Industrial standards, A balanced outlook on law, Case study: The challenger</p>
    </div>,
    <div key="3">
      <p>Safety definition, Safety and risk, Risk analysis, Assessment of safety and risk, Safe exit, Risk-benefit analysis, Safety lessons from ‘the challenger’, Case study: Power plants, Collegiality and loyalty, Collective bargaining, Confidentiality, Conflict of interests, Occupational crime, Human rights, Employee rights, Whistleblowing, Intellectual property rights.</p>
    </div>,
    <div key="4">
      <p>Globalization, Multinational corporations, Environmental ethics, Computer ethics, Weapons development, Engineers as managers, Consulting engineers, Engineers as expert witness, Engineers as advisors in planning and policymaking, Moral leadership, Codes of ethics, Engineering council of India, Codes of ethics in Business Organizations</p>
    </div>
  ];

  const tabData = [
    {
      id: 'syllabus',
      label: 'Syllabus',
      content: <Accordions accordionContent={accordionContent} />,
    },
    {
      id: 'notes',
      label: 'Notes',
      content: (
        <>
          {renderUploadButton('notes')}
          {renderCards('notes')}
        </>
      ),
    },
    {
      id: 'important',
      label: 'Important',
      content: (
        <>
          {renderUploadButton('important')}
          {renderCards('important')}
        </>
      ),
    },
    {
      id: 'books',
      label: 'Books',
      content: (
        <>
          {renderUploadButton('books')}
          {renderCards('books')}
        </>
      ),
    },

    {
      id: 'PYQ',
      label: 'PYQ',
      content: (
        <>
          {renderUploadButton('pyq')}
          {renderCards('pyq')}
        </>
      ),
    },
    {
      id: 'youtube_playlist',
      label: 'YouTube Videos',
      content: renderVideoSection(),
    },
  ];

  return (
    <section>
      <h1 className='Subjectname'>Human Values And Ethics</h1>
      {!user ? (
        <button onClick={handleGoogleLogin}>Login with Google</button>
      ) : (
        <Tabs tabData={tabData} />
      )}
    </section>
  );
};

export default HVE;


