import React, { useState } from 'react';
import { FaPlus, FaMinus } from 'react-icons/fa';

const Accordions = ({ accordionContent }) => {
  const [activeAccordion, setActiveAccordion] = useState(null);

  const handleAccordionClick = (index) => {
    setActiveAccordion(activeAccordion === index ? null : index);
  };

  return (
    <div className="accordions-container">
      {accordionContent.map((content, index) => (
        <div 
          key={index} 
          className={`accordion ${activeAccordion === index ? 'active' : ''}`}
          onClick={() => handleAccordionClick(index)} // Add click handler on the entire accordion
        >
          <div className="accordion-header">
            <span className="accordion-title">UNIT {index + 1}</span>
            <span className="accordion-toggle">
              {activeAccordion === index ? <FaMinus /> : <FaPlus />}
            </span>
          </div>
          <div
            className="accordion-content"
            style={{
              maxHeight: activeAccordion === index ? '500px' : '0',
            }}
          >
            <p>{content}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Accordions;
