import { motion } from 'framer-motion'
import { GraduationCap, Video, Laptop } from 'lucide-react'

export default function Offerings() {
  const offerings = [
    {
      icon: <GraduationCap className="w-6 h-6 md:w-8 md:h-8 text-blue-400" />,
      title: "Expert-Crafted Resources",
      description: "Curated notes and targeted practice questions by industry veterans."
    },
    {
      icon: <Video className="w-6 h-6 md:w-8 md:h-8 text-blue-400" />,
      title: "Engaging Video Lectures",
      description: "Dynamic, topic-focused videos bringing complex concepts to life."
    },
    {
      icon: <Laptop className="w-6 h-6 md:w-8 md:h-8 text-blue-400" />,
      title: "Career-Boosting Courses",
      description: "Comprehensive courses in DSA, Web Dev, Python, ML, and more."
    }
  ]

  return (
    <section className="py-16 px-4 relative overflow-hidden">
      <div className="absolute inset-0 bg-gradient-to-t from-blue-900/20 to-transparent" />
      <motion.h2 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        className="text-3xl md:text-4xl font-bold text-center mb-10 relative z-10 font-orbitron"
      >
        Unlock Your Potential
      </motion.h2>
      <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-6 relative z-10">
        {offerings.map((offering, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.2, duration: 0.8 }}
            className="group relative overflow-hidden rounded-xl hover:shadow-lg hover:shadow-purple-500/20 transition-all duration-300"
          >
            <div className="absolute inset-0 bg-gradient-to-br from-blue-600/20 to-purple-600/20 opacity-0 group-hover:opacity-100 transition-opacity duration-500" />
            <div className="relative backdrop-blur-xl bg-white/10 p-6 h-full border border-white/10 group-hover:border-white/20 transition-all duration-500">
              <div className="mb-3 transform group-hover:scale-110 transition-transform duration-500">{offering.icon}</div>
              <h3 className="text-lg md:text-xl font-semibold mb-2 font-orbitron">{offering.title}</h3>
              <p className="text-sm md:text-base text-gray-300">{offering.description}</p>
            </div>
          </motion.div>
        ))}
      </div>
    </section>
  )
}

