import { createUserWithEmailAndPassword } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import React, { useState } from "react";
import { toast, ToastContainer } from "react-toastify"; // Import ToastContainer
import "react-toastify/dist/ReactToastify.css"; // Import styles
import { useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import demovideo from "../../assets/demo.mp4";
import logo from "../../assets/LOGO SnapED.png";
import { auth, db } from "../../components/firebase";

function Register() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      if (user) {
        await setDoc(doc(db, "Users", user.uid), {
          email: user.email,
          firstName: fname,
          lastName: lname,
          photo: "",
        });
        console.log("User document created in Firestore");
      }

      toast.success("User Registered Successfully!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
      });

      setEmail("");
      setPassword("");
      setFname("");
      setLname("");

      setTimeout(() => {
        navigate("/login");
      }, 2000);
    } catch (error) {
      if (error.code === "auth/email-already-in-use") {
        toast.error("This email is already in use. Please try another one.", {
          position: "bottom-center",
          autoClose: 5000,
        });
      } else {
        toast.error(error.message, {
          position: "bottom-center",
          autoClose: 5000,
        });
      }
    }
  };

  return (
    <>
      <div className="Auth_container">
        <div className="Auth_screen-section">
          <div className="Auth_smartphone_two">
            <video className="Auth_video" autoPlay loop muted>
              <source src={demovideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>

          <div className="Auth_smartphone">
            <video className="Auth_video" autoPlay loop muted>
              <source src={demovideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>

        <div className="Auth_form-section">
          <form className="Auth_form" onSubmit={handleRegister}>
            <img className="Auth_login-logo" src={logo} alt="SnapED codeCampus" />
            <h2 className="Auth_header">Sign Up</h2>

            <div className="Auth_mb-3">
              <label>Full Name</label>
              <input
                type="text"
                className="Auth_form-control"
                placeholder="Full name"
                value={fname}
                onChange={(e) => setFname(e.target.value)}
                required
              />
            </div>

            <div className="Auth_mb-3">
              <label>College Name/Organization Name</label>
              <input
                type="text"
                className="Auth_form-control"
                placeholder="College name"
                value={lname}
                onChange={(e) => setLname(e.target.value)}
                required
              />
            </div>

            <div className="Auth_mb-3">
              <label>Email address</label>
              <input
                type="email"
                className="Auth_form-control"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>

            <div className="Auth_mb-3">
              <label>Password</label>
              <div className="Auth_password-container">
                <input
                  type={showPassword ? "text" : "password"}
                  className="Auth_form-control"
                  placeholder="Enter password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                {/* <span
                  onClick={() => setShowPassword(!showPassword)}
                  className="Auth_password-toggle-icon"
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </span> */}
              </div>
            </div>

            <div className="Auth_d-grid">
              <button type="submit" className="Auth_btn-primary">
                Sign Up
              </button>
            </div>

            <p className="Auth_forgot-password Auth_text-right">
              Have an account? <a href="/login">Login</a>
            </p>
          </form>
        </div>
      </div>

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <footer>
        <p>-- &copy; 2024 SnapED codeCampus. All rights reserved. --</p>
      </footer>
    </>
  );
}

export default Register;
