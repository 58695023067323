import React from 'react';
import Card from '../../../components/Card';

const Sem3 = ({ branch }) => {
  const notes = [
    {title: 'Foundation Of Data Science', link: '/notes/ai/Fods'},
    {title: 'Digital Logic and Circuit Design', link: '/notes/ai/DLD_AI'},
    {title: 'Crystal Reasoning and System Thinking', link: '/notes/ai/CR'},
    {title: 'Data Structures', link: '/notes/ai/ Data Structures '},
    {title: 'Principles of Artificial Intelligence', link: '/notes/ai/AI'},
    {title: 'Probability, Statistics and Linear Algebra', link: '/notes/ai/Psla'},
    {title: 'Universal Human Values II', link: '/notes/ai/UHV-II'}
    

  ];
  return (
    <>
      <section className='sem'>
        <h1>AIML-IIIrd Semester Notes</h1>
      </section>
      <section className='cards-container'>
        {notes.map((note, index) =>
        <Card key={index} title={note.title} link={note.link}/>
        )}
      </section>
    </>
  )
}

export default Sem3;
