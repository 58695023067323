import { motion } from 'framer-motion'
import { Code, Brain, Database, Terminal } from 'lucide-react'

export default function Courses() {
  const courses = [
    {
      icon: <Code className="w-6 h-6 md:w-8 md:h-8 text-blue-400" />,
      title: "Web Development",
      description: "Build responsive, dynamic websites"
    },
    {
      icon: <Brain className="w-6 h-6 md:w-8 md:h-8 text-blue-400" />,
      title: "Machine Learning",
      description: "Harness the power of AI and data"
    },
    {
      icon: <Database className="w-6 h-6 md:w-8 md:h-8 text-blue-400" />,
      title: "Data Structures",
      description: "Master efficient data organization"
    },
    {
      icon: <Terminal className="w-6 h-6 md:w-8 md:h-8 text-blue-400" />,
      title: "Python Mastery",
      description: "From basics to advanced applications"
    }
  ]

  return (
    <section className="py-16 px-4 relative overflow-hidden">
      <div className="absolute inset-0 bg-gradient-to-b from-blue-900/20 to-transparent" />
      <motion.h2 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        className="text-3xl md:text-4xl font-bold text-center mb-10 relative z-10 font-orbitron"
      >
        Explore Our Tech Universe
      </motion.h2>
      <div className="max-w-7xl mx-auto grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 relative z-10">
        {courses.map((course, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.1, duration: 0.8 }}
            className="group relative overflow-hidden rounded-xl hover:shadow-lg hover:shadow-blue-500/20 transition-all duration-300"
          >
            <div className="absolute inset-0 bg-gradient-to-br from-blue-600/20 to-purple-600/20 opacity-0 group-hover:opacity-100 transition-opacity duration-500" />
            <div className="relative backdrop-blur-xl bg-white/10 p-6 h-full border border-white/10 group-hover:border-white/20 transition-all duration-500">
              <div className="mb-3 transform group-hover:scale-110 transition-transform duration-500">{course.icon}</div>
              <h3 className="text-lg md:text-xl font-semibold mb-2 font-orbitron">{course.title}</h3>
              <p className="text-sm md:text-base text-gray-300">{course.description}</p>
            </div>
          </motion.div>
        ))}
      </div>
    </section>
  )
}

