

import { motion } from 'framer-motion'
import { Target, BookOpen, Flag } from 'lucide-react'

export default function Mission() {
  const cards = [
    {
      icon: <Target className="w-6 h-6 md:w-8 md:h-8 text-blue-400" />,
      title: "Our Mission",
      description: "Democratize tech education, making it accessible, affordable, and high-quality."
    },
    {
      icon: <BookOpen className="w-6 h-6 md:w-8 md:h-8 text-blue-400" />,
      title: "Our Vision",
      description: "Foster a global community of skilled tech professionals ready to innovate."
    },
    {
      icon: <Flag className="w-6 h-6 md:w-8 md:h-8 text-blue-400" />,
      title: "Our Goals",
      description: "Provide seamless learning with curated notes, practice questions, and hands-on projects."
    }
  ]

  return (
    <section className="pt-4 pb-16 px-4">
      <motion.h2 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        className="text-3xl md:text-4xl font-bold text-center mb-10 font-orbitron"
      >
        Empowering Your Tech Journey
      </motion.h2>
      <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-6">
        {cards.map((card, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.2, duration: 0.8 }}
            className="group relative overflow-hidden rounded-xl transition-all duration-500 hover:shadow-lg hover:shadow-blue-500/20"
          >
            <div className="absolute inset-0 bg-gradient-to-br from-blue-500/30 to-purple-500/30 opacity-0 group-hover:opacity-100 transition-opacity" />
            <div className="relative backdrop-blur-xl bg-white/10 p-6 h-full border border-white/10 group-hover:border-white/20 transition-colors">
              <div className="mb-3 transform group-hover:scale-110 transition-transform">{card.icon}</div>
              <h3 className="text-lg md:text-xl font-semibold mb-2 font-orbitron">{card.title}</h3>
              <p className="text-sm md:text-base text-gray-300">{card.description}</p>
            </div>
          </motion.div>
        ))}
      </div>
    </section>
  )
}

