import React from 'react';
import Card from '../../../components/Card';

const Sem5 = ({ branch }) => {
  const notes = [
    {title: 'Fundamentals of Deep Learning', link: '/notes/ai/DL'},
    {title: 'Computer Organization and Architecture', link: '/notes/ai/COA'},
    {title: 'Design Analysis and Algorithms', link: '/notes/ai/DAA'},
    {title: 'Principles of Entrepreneurship Mindset', link: '/notes/ai/PEM'},
    {title: 'Operating Systems', link: '/notes/cse/OS'},
    {title: 'Introduction to Internet of Things', link: '/notes/ai/IOT'},
    {title: 'Data Mining', link: '/notes/ai/Data Mining'},
    

  ];
  return (
    <>
      <section className='sem'>
        <h1> AIDS-Vth Semester Notes</h1>
      </section>
      <section className='cards-container'>
        {notes.map((note, index) =>
        <Card key={index} title={note.title} link={note.link}/>
        )}
      </section>
    </>
  )
}

export default Sem5;
