import { useEffect, useRef } from 'react'
import { motion } from 'framer-motion'

export default function Hero() {
  const titleRef = useRef(null)

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate-title')
        }
      })
    })

    if (titleRef.current) {
      observer.observe(titleRef.current)
    }

    return () => observer.disconnect()
  }, [])

  return (
    <section className="min-h-[80vh] flex flex-col items-center justify-center text-center px-4 relative mb-[-8rem]">
      <div className="absolute inset-0 bg-gradient-radial from-blue-500/10 via-transparent to-transparent" />
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        className="backdrop-blur-sm bg-white/5 rounded-2xl p-6 md:p-10 max-w-4xl mx-auto"
      >
        <h1 ref={titleRef} className="text-4xl md:text-5xl lg:text-6xl font-bold mb-4 leading-tight font-orbitron opacity-0">
          Welcome to SnapED<br />codeCampus
        </h1>
        <motion.p 
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5, duration: 0.8 }}
          className="text-lg md:text-xl lg:text-2xl text-gray-300 max-w-2xl mx-auto font-light"
        >
          Ignite Your Coding Journey with Cutting-Edge Education
        </motion.p>
      </motion.div>
      <style jsx>{`
        @keyframes titleAnimation {
          0% { opacity: 0; transform: translateY(20px); }
          100% { opacity: 1; transform: translateY(0); }
        }
        .animate-title {
          animation: titleAnimation 1s forwards;
        }
      `}</style>
    </section>
  )
}

