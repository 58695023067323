import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { toast } from "react-toastify";
import gimg from "../../assets/google.png";
import { auth, db } from "../../components/firebase";

function SignInwithGoogle({ mode = "login" }) {
  const googleLoginOrSignup = async () => {
    const provider = new GoogleAuthProvider();

    provider.setCustomParameters({
      prompt: "select_account",
    });

    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      if (user) {
        const userRef = doc(db, "Users", user.uid);
        const userDoc = await getDoc(userRef);

        if (!userDoc.exists() || mode === "signup") {
          // Save user to Firestore
          const userData = {
            email: user.email,
            firstName: user.displayName || "User", // Fallback for displayName
            photo: user.photoURL || "", // Fallback for missing photo
            lastName: "", // Default lastName
          };

          await setDoc(userRef, userData, { merge: true });
          toast.success("User signed up successfully 🎉", {
            position: "top-center",
          });
        } else {
          toast.info("User logged in successfully 🚀", {
            position: "top-center",
          });
        }

        // Redirect to the home page
        setTimeout(() => {
          window.location.href = "/";
        }, 1000);
      }
    } catch (error) {
      console.error(error.message);
      toast.error(`Failed to ${mode} with Google ❌`, {
        position: "bottom-center",
      });
    }
  };

  return (
    <div>
      <p className="continue-p">--Or continue with--</p>
      <div
        style={{ display: "flex", justifyContent: "center", cursor: "pointer" }}
        onClick={googleLoginOrSignup}
      >
        <img src={gimg} width="60%" alt="Google sign-in" />
      </div>
    </div>
  );
}

export default SignInwithGoogle;
