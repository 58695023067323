import React from 'react';
import Card from '../../../components/Card';

const Sem2 = () => {
  const notes = [
    {title: 'Applied Physics-II', link: '/notes/cse/AppliedPhysicsII'},
    {title: 'Applied Mathematics-II', link: '/notes/cse/AppliedMathII'},
    {title: 'Applied Chemistry', link: '/notes/cse/AppliedChemistry'},
    {title: 'Communication Skills', link: '/notes/cse/CommunicationSkills'},
    {title: 'Engineering Graphics', link: '/notes/cse/Engineering Graphics'},
    {title: 'Engineering Mechanics', link: '/notes/cse/Engineering_Mechanics'},
    {title: 'Electrical Science', link: '/notes/cse/Electrical_Science'},
    {title: 'Enviromental Science', link: '/notes/cse/Enviromental_Studies'},
    {title: 'Human Values and Ethics', link: '/notes/cse/Human values and Ethics'},
    {title: 'Indian Constitution', link: '/notes/cse/Indian_Constitution'},
    {title: 'Manufacturing Process', link: '/notes/cse/Manufacturing_Process'},
    {title: 'Programming in C', link: '/notes/cse/Programming_In_C'},
    {title: 'Workshop', link: '/notes/cse/Workshop'}
    

  ];
  return (
    <>
      <section className='sem'>
        <h1>IT-IInd Semester Notes</h1>
      </section>
      <section className='cards-container'>
        {notes.map((note, index) =>
        <Card key={index} title={note.title} link={note.link}/>
        )}
      </section>
    </>
  )
}

export default Sem2;
