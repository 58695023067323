import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../App.css";
import banner_img from "../assets/banner_1.png";
import { motion } from "framer-motion";

const CascadingDropdown = () => {
  const navigate = useNavigate();
  const [course, setCourse] = useState("");
  const [branch, setBranch] = useState("");
  const [semester, setSemester] = useState("");

  // Track whether the dropdown is open or closed
  const [isCourseOpen, setIsCourseOpen] = useState(false);
  const [isBranchOpen, setIsBranchOpen] = useState(false);
  const [isSemesterOpen, setIsSemesterOpen] = useState(false);

  useEffect(() => {
    if (course && branch && semester) {
      navigate(`/${course}/${branch}/${semester}`);
    }
  }, [course, branch, semester, navigate]);

  const handleCourseChange = (event) => {
    setCourse(event.target.value);
  };

  const handleBranchChange = (event) => {
    setBranch(event.target.value);
  };

  const handleSemesterChange = (event) => {
    setSemester(event.target.value);
  };

  return (
    <div className="container">
      <section id="right">
        <img src={banner_img} alt="banner_image" />
      </section>
      <section id="left">
        <motion.div
          className="select-container"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          {/* Course Dropdown */}
          <motion.div
            className="select"
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <motion.select
              value={course}
              onChange={handleCourseChange}
              onClick={() => setIsCourseOpen(!isCourseOpen)} 
              animate={{ scaleY: isCourseOpen ? 1.2 : 1 }} 
              transition={{ duration: 0.3 }} // Smooth transition for opening/closing
            >
              <option value="">Select Course</option>
              <option value="btech">BTech</option>
            </motion.select>
          </motion.div>

          {/* Branch Dropdown */}
          <motion.div
            className="select"
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            <motion.select
              value={branch}
              onChange={handleBranchChange}
              onClick={() => setIsBranchOpen(!isBranchOpen)} 
              animate={{ scaleY: isBranchOpen ? 1.2 : 1 }} // Scale effect when clicked
              transition={{ duration: 0.3 }}
            >
              <option value="">Select Branch</option>
              {course === "btech" && (
                <>
                  <option value="cse">CSE</option>
                  <option value="aiml">AIML</option>
                  <option value="aids">AIDS</option>
                  <option value="it">IT</option>
                </>
              )}
            </motion.select>
          </motion.div>

          {/* Semester Dropdown */}
          <motion.div
            className="select"
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.6 }}
          >
            <motion.select
              value={semester}
              onChange={handleSemesterChange}
              onClick={() => setIsSemesterOpen(!isSemesterOpen)}
              animate={{ scaleY: isSemesterOpen ? 1.2 : 1 }} // Scale effect when clicked
              transition={{ duration: 0.3 }}
            >
              <option value="">Select Semester</option>
              <option value="1st">1st Semester</option>
              <option value="2nd">2nd Semester</option>
              <option value="3rd">3rd Semester</option>
              <option value="4th">4th Semester</option>
              <option value="5th">5th Semester</option>
            </motion.select>
          </motion.div>
        </motion.div>
      </section>
    </div>
  );
};

export default CascadingDropdown;
