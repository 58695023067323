import React from 'react';
import Card from '../../../components/Card';

const Sem4 = ({ branch }) => {
  const notes = [
    {title: 'Object Oriented Programming', link: '/notes/ai/OOPS'},
    {title: 'Database Management System', link: '/notes/ai/DBMS'},
    {title: 'Software Engineering', link: '/notes/ai/SE'},
    {title: 'Computer Networks and Internet Protocol', link: '/notes/ai/CN'},
    {title: 'Fundamentals of Machine Learning', link: '/notes/ai/ML'},
    {title: 'Computational Methods', link: '/notes/ai/CM'},
    {title: 'Effective Technical Writing', link: '/notes/ai/ETW'}
    

  ];
  return (
    <>
      <section className='sem'>
        <h1>AIML-IVth Semester Notes</h1>
      </section>
      <section className='cards-container'>
        {notes.map((note, index) =>
        <Card key={index} title={note.title} link={note.link}/>
        )}
      </section>
    </>
  )
}

export default Sem4;
