import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import styled from '@emotion/styled';
import logo from "../assets/LOGO SnapED.png";
import { useNavigate } from 'react-router-dom';

const NavContainer = styled(motion.nav)`
  overflow: hidden; /* Prevents content from overflowing */
  height: 80px; /* Fixed height */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 1rem 2rem;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s ease;
  margin: 0 auto;

  @media (max-width: 1440px) {
    padding: 1rem;
  }
`;

const LogoContainer = styled(motion.div)`
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
`;

const LogoImage = styled.img`
  height: auto; // Increase the height to make the logo larger
  width: 150px; // Maintain aspect ratio
  max-width: 200px; // Set a maximum width to prevent it from growing too large
    @media (max-width: 768px) {
    display: none;
  }
`;

const LogoText = styled.div`
  font-family: 'Orbitron', sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  color: #fff;
  background: linear-gradient(45deg, #00ffff, #00ff88);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const MenuItems = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;

  @media (max-width: 768px) {
    display: none;
  }
`;

const MobileMenu = styled(motion.div)`
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(10, 25, 47, 0.95);
  backdrop-filter: blur(10px);
  padding: 5rem 2rem;
  min-width: 250px;
  z-index: 999;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }
`;

const MobileMenuButton = styled.button`
  display: none;
  background: transparent;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
  z-index: 1000;

  @media (max-width: 768px) {
    display: block;
  }
`;

const MenuItem = styled(motion(Link))`
  color: #fff;
  text-decoration: none;
  font-size: 1rem;
  cursor: pointer;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  transition: all 0.3s ease;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 2px;
    background: linear-gradient(45deg, #00ffff, #00ff88);
    transition: width 0.3s ease;
  }

  &:hover::after {
    width: 80%;
  }

  &:hover {
    color: #00ffff;
  }

  &.active {
    color: #00ffff;
    &::after {
      width: 80%;
    }
  }
`;

const ContributeButton = styled(motion.a)`
  background: linear-gradient(45deg, #00ffff, #00ff88);
  border: none;
  padding: 0.8rem 1.5rem;
  border-radius: 8px;
  color: #000;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-size: 0.9rem;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 0 20px rgba(0, 255, 255, 0.4);
  }
`;

const Navbar = () => {
  const [scrolled, setScrolled] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate('/');
  };

  return (
    <>
      <NavContainer
        className={`glass-effect ${scrolled ? 'neon-border' : ''}`}
        initial={{ y: -100 }}
        animate={{ y: 0 }}
        transition={{ duration: 0.5 }}
      >
    <LogoContainer
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      onClick={handleLogoClick} // Added onClick handler
    >
      <LogoImage src={logo} alt="SnapED Logo" />
    </LogoContainer>

        <MenuItems>
          <MenuItem to="/" whileHover={{ scale: 1.1 }} className={location.pathname === '/' ? 'active' : ''}>
            Home
          </MenuItem>
          <MenuItem to="/tech-library" whileHover={{ scale: 1.1 }} className={location.pathname === '/tech-library' ? 'active' : ''}>
            Tech Library
          </MenuItem>
          <MenuItem to="/event" whileHover={{ scale: 1.1 }} className={location.pathname === '/event' ? 'active' : ''}>
            Events
          </MenuItem>
          <MenuItem to="/about" whileHover={{ scale: 1.1 }} className={location.pathname === '/about' ? 'active' : ''}>
            About
          </MenuItem>
          <MenuItem to="/contactus" whileHover={{ scale: 1.1 }} className={location.pathname === '/contactus' ? 'active' : ''}>
            Contact
          </MenuItem>
          <MenuItem to="/profile" whileHover={{ scale: 1.1 }} className={location.pathname === '/profile' ? 'active' : ''}>
            Profile
          </MenuItem>
          <ContributeButton
            href="https://forms.gle/ufk6ibsZdRkhxuTc8"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            Contribute
          </ContributeButton>
        </MenuItems>
      </NavContainer>

    </>
  );
};

export default Navbar;