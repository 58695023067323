import React, { useState, useEffect, useMemo } from 'react';
import { db, auth } from './firebase';
import { collection, getDocs, addDoc, updateDoc, deleteDoc, doc } from 'firebase/firestore';
import ReactQuill from 'react-quill';
import { FaExpandAlt, FaTrashAlt, FaEdit  } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';  // Import useNavigate from react-router-dom
import 'react-quill/dist/quill.snow.css';
import { motion } from 'framer-motion';

const TutorialCards = () => {
  const [tutorials, setTutorials] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [newTutorial, setNewTutorial] = useState({ title: '', description: '', topics: [{ title: '', content: '' }] });
  const [isFullscreen, setIsFullscreen] = useState(null);
  const [isEditing, setIsEditing] = useState(null);
  const [updatedTutorial, setUpdatedTutorial] = useState({ title: '', description: '', topics: [] });
  const [currentTutorialIndex, setCurrentTutorialIndex] = useState(null);
  const [currentTopicIndex, setCurrentTopicIndex] = useState(0);
  const user = auth.currentUser;
  const isAdmin = user?.email === 'snaped4338@gmail.com';
  const navigate = useNavigate();  // Hook to navigate

  // Fetch tutorials from Firestore
  useEffect(() => {
    const fetchTutorials = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'posts'));
        const tutorialsData = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setTutorials(tutorialsData);
      } catch (error) {
        console.error('Error fetching tutorials:', error);
      }
    };
    fetchTutorials();
  }, []);

  // Filtered tutorials based on search query
  const filteredTutorials = useMemo(
    () =>
      tutorials.filter(
        (tutorial) =>
          tutorial.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
          tutorial.description.toLowerCase().includes(searchQuery.toLowerCase())
      ),
    [searchQuery, tutorials]
  );

  const handleAddTutorial = async () => {
    if (!newTutorial.title || !newTutorial.description || !newTutorial.topics[0].content) {
      alert('Please fill all fields');
      return;
    }
    try {
      const docRef = await addDoc(collection(db, 'posts'), newTutorial);
      setTutorials([...tutorials, { id: docRef.id, ...newTutorial }]);
      setNewTutorial({ title: '', description: '', topics: [{ title: '', content: '' }] });
    } catch (error) {
      console.error('Error adding tutorial:', error);
    }
  };

  const handleDeleteTutorial = async (id) => {
    try {
      await deleteDoc(doc(db, 'posts', id));
      setTutorials(tutorials.filter((tutorial) => tutorial.id !== id));
    } catch (error) {
      console.error('Error deleting tutorial:', error);
    }
  };

  const handleUpdateTutorial = async (id) => {
    if (!updatedTutorial.title || !updatedTutorial.description || !updatedTutorial.topics[0].content) {
      alert('Please fill all fields to update');
      return;
    }
    try {
      await updateDoc(doc(db, 'posts', id), updatedTutorial);
      setTutorials(
        tutorials.map((tutorial) =>
          tutorial.id === id ? { ...tutorial, ...updatedTutorial } : tutorial
        )
      );
      setIsEditing(null);
      setUpdatedTutorial({ title: '', description: '', topics: [] });
    } catch (error) {
      console.error('Error updating tutorial:', error);
    }
  };

  const toggleFullscreen = (id, index) => {
    setIsFullscreen(isFullscreen === id ? null : id);
    setCurrentTutorialIndex(index);
    setCurrentTopicIndex(0); // Reset topic index when entering fullscreen
  };

  const handleNextTopic = () => {
    if (currentTopicIndex < updatedTutorial.topics.length - 1) {
      setCurrentTopicIndex(currentTopicIndex + 1);
    }
  };

  const handlePreviousTopic = () => {
    if (currentTopicIndex > 0) {
      setCurrentTopicIndex(currentTopicIndex - 1);
    }
  };

  // Navigate to tutorial detail page when View button is clicked
  const handleViewTutorial = (tutorialTitle) => {
    // This will change the URL to include the tutorial title
    navigate(`/tutorial/${tutorialTitle}`);
  };

  return (
    <div className="post_tutorial-cards-container">
              <motion.h1
          className="Post_h1 text-7xl font-bold text-white drop-shadow-3xl sm:text-5xl md:text-6xl"
          initial={{ scale: 0.8, rotateX: -30 }}
          animate={{ scale: 1, rotateX: 0 }}
          transition={{ duration: 0.8, ease: "easeOut" }}
          style={{
            textShadow: "0px 4px 10px rgba(0,0,0,0.8), 0px 8px 15px rgba(0,0,0,0.6)",
            fontSize: "70px",
            fontFamily: "cursive",
            fontWeight: "bolder",
            textAlign: "center",
            marginTop: "90px"
          }}
        >
          Tech Library
        </motion.h1>

      {/* Search Bar */}
      <div className="post_search-bar">
        <input
          type="text"
          placeholder="Search tutorials..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </div>

      {/* Admin Panel */}
      {isAdmin && (
        <div className="post_admin-panel">
          <h2>Admin Panel</h2>
          <input
            type="text"
            placeholder="Title"
            value={newTutorial.title}
            onChange={(e) => setNewTutorial({ ...newTutorial, title: e.target.value })}
          />
          <textarea
            placeholder="Description"
            value={newTutorial.description}
            onChange={(e) => setNewTutorial({ ...newTutorial, description: e.target.value })}
          ></textarea>

          {/* Add multiple topics */}
          {newTutorial.topics.map((topic, index) => (
            <div key={index} className="post_topic">
              <input
                type="text"
                placeholder={`Topic ${index + 1} Title`}
                value={topic.title}
                onChange={(e) =>
                  setNewTutorial({
                    ...newTutorial,
                    topics: newTutorial.topics.map((t, i) =>
                      i === index ? { ...t, title: e.target.value } : t
                    ),
                  })
                }
              />
              <ReactQuill
                value={topic.content}
                onChange={(value) =>
                  setNewTutorial({
                    ...newTutorial,
                    topics: newTutorial.topics.map((t, i) =>
                      i === index ? { ...t, content: value } : t
                    ),
                  })
                }
                modules={quillModules}
                placeholder={`Write content for Topic ${index + 1}`}
              />
            </div>
          ))}
          <button onClick={() => setNewTutorial({ ...newTutorial, topics: [...newTutorial.topics, { title: '', content: '' }] })}>
            Add Topic
          </button>
          <button onClick={handleAddTutorial}>Add Tutorial</button>
        </div>
      )}

      {/* Tutorial Cards */}
      <div className="post_tutorial-cards">
        {filteredTutorials.length > 0 ? (
          filteredTutorials.map((tutorial, index) => (
            <div key={tutorial.id} className="post_tutorial-card">
              <h3>{tutorial.title}</h3>
              <p>{tutorial.description}</p>
              <button className="post_view" onClick={() => handleViewTutorial(tutorial.title)}>
                <FaExpandAlt /> View
              </button>

              {isAdmin && (
                <div className="post_admin-actions">
                  <button className='adminbutton' onClick={() => handleDeleteTutorial(tutorial.id)}>
                    <FaTrashAlt /> Delete
                  </button>
                  <button
                  className='adminbutton'
                    onClick={() => {
                      setIsEditing(tutorial.id);
                      setUpdatedTutorial({
                        title: tutorial.title,
                        description: tutorial.description,
                        topics: tutorial.topics,
                      });
                    }}
                  >
                    <FaEdit /> Update
                  </button>
                </div>
              )}

              {isEditing === tutorial.id && (
                <div className="post_edit-modal">
                  <h3>Edit Tutorial</h3>
                  <input
                    type="text"
                    value={updatedTutorial.title}
                    onChange={(e) => setUpdatedTutorial({ ...updatedTutorial, title: e.target.value })}
                  />
                  <textarea
                    value={updatedTutorial.description}
                    onChange={(e) => setUpdatedTutorial({ ...updatedTutorial, description: e.target.value })}
                  ></textarea>

                  {/* Edit topics */}
                  {updatedTutorial.topics.map((topic, index) => (
                    <div key={index} className="post_topic">
                      <input
                        type="text"
                        value={topic.title}
                        onChange={(e) =>
                          setUpdatedTutorial({
                            ...updatedTutorial,
                            topics: updatedTutorial.topics.map((t, i) =>
                              i === index ? { ...t, title: e.target.value } : t
                            ),
                          })
                        }
                      />
                      <ReactQuill
                        value={topic.content}
                        onChange={(value) =>
                          setUpdatedTutorial({
                            ...updatedTutorial,
                            topics: updatedTutorial.topics.map((t, i) =>
                              i === index ? { ...t, content: value } : t
                            ),
                          })
                        }
                        modules={quillModules}
                        placeholder={`Update content for Topic ${index + 1}`}
                      />
                    </div>
                  ))}
                  <button onClick={() => handleUpdateTutorial(tutorial.id)}>Save Changes</button>
                  <button onClick={() => setIsEditing(null)}>Cancel</button>
                </div>
              )}
            </div>
          ))
        ) : (
          <p>No tutorials found.</p>
        )}
      </div>
    </div>
  );
};

const quillModules = {
  toolbar: [
    [{ header: '1' }, { header: '2' }, { font: [] }],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ align: [] }],
    ['link'],
    ['blockquote'],
    ['image'],
  ],
};

export default TutorialCards;
