import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { db } from './firebase';
import { collection, getDocs } from 'firebase/firestore';

const TutorialDetail = () => {
  const { title } = useParams(); 
  const [tutorial, setTutorial] = useState(null); 
  const [currentTopicIndex, setCurrentTopicIndex] = useState(0); 

  useEffect(() => {
    const fetchTutorial = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'posts'));
        const tutorialData = querySnapshot.docs
          .map((doc) => ({ id: doc.id, ...doc.data() }))
          .find((tutorial) => tutorial.title.toLowerCase().replace(/\s+/g, '-') === title);

        if (tutorialData) {
          setTutorial(tutorialData);
        } else {
          console.log('No such tutorial found!');
        }
      } catch (error) {
        console.error('Error fetching tutorial:', error);
      }
    };

    fetchTutorial();
  }, [title]);

  // Handle Sidebar Navigation
  const handleTopicClick = (index) => {
    setCurrentTopicIndex(index);
  };

  if (!tutorial) {
    return <div class="flex items-center justify-center min-h-screen">
    <div
      class="mx-auto w-[90%] sm:w-[500px] bg-gray-950 rounded-xl overflow-hidden drop-shadow-xl"
    >
      <div class="bg-[#333] flex items-center p-[5px] text-white relative">
        <div class="flex absolute left-3">
          <span class="h-3.5 w-3.5 bg-[#ff605c] rounded-xl mr-2"></span>
          <span class="h-3.5 w-3.5 bg-[#ffbd44] rounded-xl mr-2"></span>
          <span class="h-3.5 w-3.5 bg-[#00ca4e] rounded-xl"></span>
        </div>
        <div class="flex-1 text-center text-white">status</div>
      </div>
      <div class="p-2.5 text-[#0f0]">
        <div>
          <span class="mr-2">Loading</span>
          <span class="animate-[ping_1.5s_0.5s_ease-in-out_infinite]">.</span>
          <span class="animate-[ping_1.5s_0.7s_ease-in-out_infinite]">.</span>
          <span class="animate-[ping_1.5s_0.9s_ease-in-out_infinite]">.</span>
        </div>
      </div>
    </div>
  </div>;
  }

  return (
    <div className="tutorial-detail">

      <div className="sidebar">
        <h3>Topics</h3>
        <ul>
          {tutorial.topics.map((topic, index) => (
            <li
              key={index}
              onClick={() => handleTopicClick(index)}
              style={{
                cursor: 'pointer',
                fontWeight: currentTopicIndex === index ? 'bold' : 'normal',
                backgroundColor: currentTopicIndex === index ? '#007bff' : 'transparent',
                color: currentTopicIndex === index ? '#fff' : '#000', 
                padding: '10px',
                transition: 'background-color 0.3s ease',
              }}
            >
              {topic.title}
            </li>
          ))}
        </ul>
      </div>

     
      <div className="topic-content">
      <h2 className='topic-h2'>{tutorial.title}</h2>
        <h3>{tutorial.topics[currentTopicIndex].title}</h3>
        <div
          dangerouslySetInnerHTML={{
            __html: tutorial.topics[currentTopicIndex].content,
          }}
        />
      </div>
    </div>
  );
};

export default TutorialDetail;
